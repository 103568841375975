import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import dragImg from "../../assets/images/dragAndDrop.png";

const DragDropBox = styled(Box)(({ isDragging }) => ({
  border: "0.5px dashed #58A942",
  padding: "50px",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: isDragging ? "#e3f2fd" : "#fafafa",
  transition: "background-color 0.2s",
}));

const DragAndDrop = ({ onFileSelected }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) onFileSelected(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) onFileSelected(file);
  };

  return (
    <DragDropBox
      isDragging={isDragging}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Box sx={{ mb: 2 }}>
        {" "}
        <img src={dragImg} alt="dragImg" />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="body1"
          sx={{ mr: 1, color: "#525252", fontSize: "13px" }}
        >
          Drag and drop or
        </Typography>
        <input
          type="file"
          style={{ display: "none" }}
          accept=".zip"
          onChange={handleFileInputChange}
          id="fileInput"
        />
        <label htmlFor="fileInput">
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              color: "#525252",
              textDecoration: "underline",
              fontSize: "13px",
            }}
          >
            Choose file
          </Typography>
        </label>
      </Box>
    </DragDropBox>
  );
};

export default DragAndDrop;
