import { createContext, useContext, useState, useEffect } from "react";
import axiosServices from "../utils/axiosServices";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (token && storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = async (userData) => {
    try {
      const response = await axiosServices.post("/auth/login", userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Login response:", response);
      const token = response.data.token;

      if (token) {
        localStorage.setItem("token", token);
        setUser(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
        console.log("User state after login:", response.data);
        return { success: true, response };
      } else {
        console.error("No token found in response.");
        return { success: false, message: "No token found." };
      }
    } catch (error) {
      console.error(
        "Error during login:",
        error.response ? error.response.data : error
      );
      const errorMessage = error.response?.data?.message || "Login Failed";
      return { success: false, message: errorMessage };
    }
  };

  const register = async (userData) => {
    try {
      const response = await axiosServices.post("/users/users", userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const logout = () => {
    console.log("Logging out...");
    setUser(null);
    localStorage.clear();
  };

  const isAuthenticated = !!user;

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  const sendPasswordResetEmail = async (email) => {
    try {
      const response = await axiosServices.post("/auth/sent-otp", {
        email,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || "Failed to send email.");
    }
  };

  const sendOtp = async (email) => {
    try {
      const response = await axiosServices.post("/auth/send-otp", { email });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || "Failed to send OTP.");
    }
  };

  const verifyOtp = async (email, otp) => {
    try {
      const response = await axiosServices.post("/auth/verify-otp", {
        email,
        otp,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || "Invalid OTP.");
    }
  };

  const resetPassword = async (email, newPassword) => {
    try {
      const response = await axiosServices.post("/auth/reset-password", {
        email,
        newPassword,
      });
      return response.data;
    } catch (error) {
      throw new Error(
        error.response.data.message || "Failed to reset password."
      );
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        register,
        logout,
        isAuthenticated,
        sendPasswordResetEmail,
        sendOtp,
        verifyOtp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
export default AuthProvider;
