import * as Yup from "yup";
import ERROR_MESSAGES from "../constants/errorMessages";

export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      ERROR_MESSAGES.INVALID_EMAIL
    )
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED),
  password: Yup.string().required(ERROR_MESSAGES.REQUIRED),
});
