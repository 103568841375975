import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Breadcrumbs,
  Link,
  Modal,
  Button,
  Skeleton,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation, useNavigate } from "react-router-dom";
import ReusableAccordion from "../../components/ReusableAccordion/ReusableAccordion";
import axiosServices from "../../utils/axiosServices";
import { useAuth } from "../../context/AuthContext";
import PushPinIconImg from "../../assets/images/pinIcon.svg";
import vector from "../../assets/images/Vector.svg";
import ExecutiveSummary from "./ExecutiveSummary";
import PinnedTopics from "./PinnedTopics";
import { MermaidDiagram } from "@lightenna/react-mermaid-diagram";
import CloseIcon from "@mui/icons-material/Close";
import Chat from "./Chat";

const ProjectDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const projectName = location.state?.projectName;
  const project = location.state?.project;
  const projectId = project?.project_id;
  const userEmail = user?.user?.email;

  const [pinnedTopics, setPinnedTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [mermaidCode, setMermaidCode] = useState({});
  const [noDataMessage, setNoDataMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedMermaidCode, setSelectedMermaidCode] = useState("");
  const [error, setError] = useState(null);
  const [noDataPinnedMessage, setNoDataPinnedMessage] = useState("");
  const [groupedTopics, setGroupedTopics] = useState({});
  const [, setPinnedMessages] = useState(new Set());

  useEffect(() => {
    setLoading(false);
  }, []);

  const fetchMermaidCode = useCallback(async () => {
    try {
      setIsLoading(true);
      setNoDataMessage("");
      const response = await axiosServices.get(`/diagram`, {
        params: {
          project_id: projectId,
          email: userEmail,
        },
      });
      if (!response.data) {
        setMermaidCode({});
        setNoDataMessage(" ");
        return;
      }
      const processedData = JSON.parse(response.data);

      if (processedData) {
        setMermaidCode(processedData);
      } else {
        setMermaidCode({});
        setNoDataMessage("Error: Mermaid code not available.");
      }
    } catch (error) {
      console.error("Error fetching mermaid code:", error);
      setMermaidCode({});
      setNoDataMessage("Error: Failed to fetch mermaid code.");
    } finally {
      setIsLoading(false);
    }
  }, [projectId, userEmail]);

  useEffect(() => {
    if (projectId && userEmail) {
      fetchMermaidCode();
    }
  }, [fetchMermaidCode, projectId, userEmail]);

  const handleAccordionClick = (title, mermaidCode) => {
    console.log("Accordion clicked:", title, mermaidCode);
    setSelectedMermaidCode({ title, mermaid_code: mermaidCode });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMermaidCode("");
  };

  const fetchPinnedTopicsData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      setNoDataPinnedMessage("");

      const response = await axiosServices.get("/pin", {
        params: {
          project_id: projectId,
          email: userEmail,
        },
      });

      const topics = response.data;

      if (!topics || topics.message === "No pinned topics here") {
        setGroupedTopics({});
        setNoDataPinnedMessage("No pinned topics here");
        return; // Exit early if there are no topics
      }

      const newPinnedMessages = new Set(
        topics.map((topic) => topic.pin_content)
      );
      setPinnedMessages(newPinnedMessages);

      const grouped = topics.reduce((acc, topic) => {
        const { topic_name, pin_content, id } = topic;
        if (!acc[topic_name]) {
          acc[topic_name] = [];
        }
        acc[topic_name].push({ id, content: pin_content });
        return acc;
      }, {});

      setGroupedTopics(grouped);
      setPinnedTopics(topics); // Update pinned topics state
    } catch (err) {
      console.error("Error fetching pinned topics:", err);
      setError("Failed to fetch pinned topics. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [projectId, userEmail]);

  useEffect(() => {
    fetchPinnedTopicsData();
  }, [fetchPinnedTopicsData]);

  const updatePinnedTopics = (newPinnedTopic) => {
    setPinnedTopics((prev) => [...prev, newPinnedTopic]);
  };

  const handlePinMessage = async (topicName, msgContent) => {
    if (!projectId) {
      console.error("Error: Project ID is undefined.");
      return false;
    }

    try {
      const response = await axiosServices.post(
        `/pin?project_id=${projectId}&email=${userEmail || ""}`,
        {
          topic_name: topicName,
          pin_content: msgContent,
        }
      );
      console.log("Pinning response:", response);

      updatePinnedTopics({
        id: response.data.id,
        topic_name: topicName,
        pin_content: msgContent,
      });
      fetchPinnedTopicsData();
      return true;
    } catch (error) {
      console.error("Error pinning message:", error);
      return false;
    }
  };

  const handleSendMessage = async (message) => {
    if (!message.trim()) return;

    const userMessage = message.trim();
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: userMessage },
    ]);

    try {
      const response = await axiosServices.post(`/messages`, {
        userQuestion: userMessage,
        projectId: projectId,
        email: userEmail,
      });

      const assistantResponse = response?.data?.result || "No response";

      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: assistantResponse },
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "error", content: "Failed to send message" },
      ]);
    }
  };

  const handleDelete = async (pinId) => {
    if (!pinId) {
      console.error("Pin ID is undefined or pin not found");
      return;
    }
    try {
      const response = await axiosServices.delete(`/pin/${pinId}`, {
        params: {
          pin_id: pinId,
          email: user?.user?.email,
        },
      });
      console.log("Delete response:", response);

      // Update the pinnedTopics state to remove the deleted topic
      setPinnedTopics((prevTopics) =>
        prevTopics.filter((topic) => topic.id !== pinId)
      );

      // Call fetchPinnedTopicsData to refresh the pinned topics if needed
      fetchPinnedTopicsData(); // Optional, if you want to ensure the server state is in sync
    } catch (error) {
      console.error("Error deleting the pin:", error.message);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: "15px",

        "@media (max-width: 768px)": {
          padding: "10px",
          pb: 10,
        },
      }}
    >
      <Box
        sx={{
          ml: 2,
          top: 0,
          zIndex: 1,
          position: "sticky",
          backgroundColor: "white",
          "@media (max-width: 768px)": {
            ml: 0,
          },
        }}
      >
        <Breadcrumbs
          separator={
            <ArrowForwardIosIcon
              sx={{
                fontSize: 14,
                color: "black",
                "@media (max-width: 768px)": {
                  fontSize: 12,
                },
              }}
            />
          }
          aria-label="breadcrumb"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "@media (max-width: 768px)": {
                flexDirection: "row",
              },
            }}
          >
            <Box
              component="img"
              src={vector}
              alt="All Projects"
              sx={{
                width: 20,
                height: 20,
                marginRight: 1.5,
                "@media (max-width: 768px)": {
                  width: 16,
                  height: 16,
                },
              }}
            />
            <Link
              underline="hover"
              color="inherit"
              onClick={() => navigate(-1)}
              sx={{
                fontSize: "17px",
                cursor: "pointer",
                color: "#525252",
                textDecoration: "none",
                "@media (max-width: 768px)": {
                  fontSize: "15px",
                },
              }}
            >
              All Projects
            </Link>
          </Box>

          <Box
            sx={{
              fontSize: "17px",
              fontWeight: "500",
              color: "black",
              "@media (max-width: 768px)": {
                fontSize: "15px",
              },
            }}
          ></Box>
        </Breadcrumbs>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#525252",
            fontSize: "25px",
            fontWeight: "bold",
            pb: 1,
            pl: "32px",
            pt: "20px",
          }}
        >
          {projectName ? `${projectName}` : "Project Details"}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "100vh",
          overflowX: "auto",
          pb: 14,
          "@media (max-width: 1024px)": {
            flexDirection: "column",
            height: "auto",
            pb: 8,
          },
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            paddingRight: "10px",
            height: "100%",
            overflowY: "auto",
            justifyContent: "space-between",
            "@media (max-width: 768px)": {
              flex: 1,
              padding: "10px",
            },
          }}
        >
          <ExecutiveSummary sx={{ overflowY: "auto", maxHeight: "400px" }} />
          <Chat
            project={project}
            messages={messages}
            setMessages={setMessages}
            pinnedTopics={pinnedTopics}
            handlePinMessage={handlePinMessage}
            handleSendMessage={handleSendMessage}
            handleDelete={handleDelete}
            sx={{ overflowY: "auto", maxHeight: "400px" }}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            paddingRight: "10px",

            pl: 2,
            "@media (max-width: 1024px)": {
              p: 5,
              pt: 5,
            },
            "@media (max-width: 768px)": {
              p: 3,
              pt: 3,
            },
          }}
        >
          <Box>
            {isLoading ? (
              <Box>
                {[...Array(1)].map((_, index) => (
                  <Box key={index} sx={{ marginBottom: "16px" }}>
                    <ReusableAccordion>
                      <Skeleton
                        variant="rectangular"
                        height={50}
                        sx={{ marginTop: 1 }}
                      />
                    </ReusableAccordion>
                  </Box>
                ))}
              </Box>
            ) : noDataMessage ? (
              <Typography
                variant="h6"
                sx={{
                  color: "#525252",
                  fontSize: "18px",
                  "@media (max-width: 768px)": {
                    fontSize: "16px",
                  },
                }}
              >
                {noDataMessage}
              </Typography>
            ) : Object.keys(mermaidCode).length === 0 ? (
              <Typography sx={{ textAlign: "center", color: "#525252", mt: 4 }}>
                No Mermaid diagrams found.
              </Typography>
            ) : (
              <ReusableAccordion key="allMermaidCodes" title="Diagrams">
                <Box
                  sx={{
                    display: "flex",
                    height: "80px",
                    width: "300px",
                    overflow: "hidden",
                    marginTop: "-15px",
                  }}
                >
                  {Object.entries(mermaidCode || {}).map(
                    ([title, { mermaid_code }]) => (
                      <Box
                        key={title}
                        sx={{
                          flex: "1 1 30%",
                          margin: "15px 5px",
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                          backgroundColor: "#fff",
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleAccordionClick(title, mermaid_code)
                        }
                      >
                        <MermaidDiagram
                          style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                            overflow: "hidden",
                          }}
                        >
                          {mermaid_code}
                        </MermaidDiagram>
                      </Box>
                    )
                  )}
                </Box>
              </ReusableAccordion>
            )}
          </Box>

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                width: "90%",
                height: "70%",
                maxWidth: "95%",
                maxHeight: "95%",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                {selectedMermaidCode?.title || "Untitled"}
              </Typography>

              <Button
                onClick={handleCloseModal}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  bgcolor: "transparent",
                  color: "#525252",
                  "&:hover": {
                    bgcolor: "rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <CloseIcon />
              </Button>

              <Box>
                {console.log("Selected Mermaid Code:", selectedMermaidCode)}
                <MermaidDiagram
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                >
                  {selectedMermaidCode.mermaid_code}
                </MermaidDiagram>
              </Box>
            </Box>
          </Modal>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              mt: 3,
              "@media (max-width: 768px)": {
                mt: 2,
              },
            }}
          >
            <img
              src={PushPinIconImg}
              alt=""
              style={{
                marginRight: "8px",
                marginTop: "5px",
              }}
            />

            <Typography
              variant="h6"
              sx={{
                color: "#525252",
                fontSize: "18px",
                fontWeight: "bold",
                "@media (max-width: 768px)": {
                  fontSize: "16px",
                },
              }}
            >
              Pinned Topics
            </Typography>
          </Box>

          <PinnedTopics
            projectId={projectId}
            userEmail={userEmail}
            fetchPinnedTopics={fetchPinnedTopicsData}
            groupedTopics={groupedTopics}
            isLoading={isLoading}
            error={error}
            noDataPinnedMessage={noDataPinnedMessage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectDetails;
