import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import {
  Typography,
  Box,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useAuth } from "../../context/AuthContext";
import logo from "../../assets/images/logo.svg";
import FormikInput from "../../components/FormikInput/FormikInput";
import MuiLoadingButton from "../../components/Buttons/LoadingButton";
import { loginValidationSchema } from "../../Validations/LoginValidation";
import forgotPasswordValidationSchema from "../../Validations/ForgotPasswordValidation";
import authService from "../../services/AuthServices";
import OtpInput from "../../components/OtpInput";
import * as Yup from "yup";

function Login() {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [, setEmail] = useState("");
  const [, setOtp] = useState(["", "", "", "", "", ""]);
  const [, setNewPassword] = useState("");
  const [, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [, setSuccessMessage] = useState("");
  const [, setForgotPasswordError] = useState("");

  const initialValues = {
    email: "",
    password: "",
  };

  const handleLogin = async (values, { setSubmitting }) => {
    setError("");
    try {
      const result = await authService.login(values);
      setUser(result);
      localStorage.setItem("user", JSON.stringify(result));
    } catch (err) {
      setSubmitting(false);
      if (err.response) {
        if (err.response.status === 401) {
          setError("Invalid credentials, please try again.");
        } else if (err.response.status === 500) {
          setError("Server error, please try again later.");
        } else {
          setError(
            err.response?.data?.error || "An unexpected error occurred."
          );
        }
      } else if (err.request) {
        setError("Network error, please try again later.");
      } else {
        setError("An error occurred, please try again.");
      }
    }
  };

  const handleForgotPasswordOpen = () => setForgotPasswordOpen(true);

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
    resetForgotPasswordState();
    setForgotPasswordError("");
  };

  const resetForgotPasswordState = () => {
    setEmail("");
    setOtp(["", "", "", "", "", ""]);
    setNewPassword("");
    setConfirmPassword("");
    setStep(1);
    setError("");
    setForgotPasswordError("");
    setSuccessMessage("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.background.dark,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "1100px",
          minHeight: "75vh",
          bgcolor: "white",
          boxShadow: 3,
          flexDirection: matchDownSm ? "column-reverse" : "row",
          borderRadius: 2,
          overflow: "hidden",
          alignItems: "stretch",
          justifyContent: "stretch",
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: matchDownSm ? 6 : 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            onSubmit={handleLogin}
          >
            {({ isSubmitting }) => (
              <Form>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ mb: 3, fontWeight: "bold" }}
                >
                  Sign In
                </Typography>

                <FormikInput
                  name="email"
                  label="Email Address"
                  type="email"
                  placeholder="e.g. test@example.com"
                />
                <FormikInput
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Enter your password"
                />

                {error && (
                  <Typography color="error" sx={{ fontSize: "15px" }}>
                    {error}
                  </Typography>
                )}

                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={handleForgotPasswordOpen}
                  >
                    Forgot Password?
                  </Typography>
                </Box>

                <MuiLoadingButton
                  type="submit"
                  loading={isSubmitting}
                  loadingIndicator="Loading…"
                  sx={{ textTransform: "none", mt: 2 }}
                >
                  Sign In
                </MuiLoadingButton>
              </Form>
            )}
          </Formik>

          <Typography
            sx={{
              mt: 2,
              textAlign: "center",
              color: "inherit",
              cursor: "pointer",
            }}
          >
            Don't have an account?{" "}
            <span
              style={{
                color: theme.palette.primary.main,
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => navigate("/register")}
            >
              Sign Up
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            minHeight: "75vh",
            background: theme.palette.background.light,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: matchDownSm ? 6 : 8,
            color: "white",
            position: "relative",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "relative",
              flex: "0 0 auto",
              width: "350px",
              height: "auto",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "50%", // Covers the bottom 50% of the image
                background: "rgba(255, 255, 255, 0.1)", // Add a light blur-like overlay
                backdropFilter: "blur(10px)", // Apply blur effect
                WebkitBackdropFilter: "blur(10px)", // Safari compatibility
                zIndex: 1, // Ensure it's above the image
              }}
            />
          </Box>

          <Box sx={{}}>
            <Typography
              sx={{
                fontSize: 17,
                color: "#626262",
              }}
              align="center"
            >
              A virtual AI agent at a fraction of cost to understand legacy
              codebases!
            </Typography>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={forgotPasswordOpen}
        onClose={handleForgotPasswordClose}
        maxWidth="sm"
        PaperProps={{
          sx: {
            width: "400px",
            maxWidth: "90%",
            margin: "0 auto",
          },
        }}
      >
        <DialogTitle>
          {step === 1
            ? "Forgot Password"
            : step === 2
              ? "Enter the OTP sent to your email"
              : "Reset Password"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              email: "",
              otp: ["", "", "", "", "", ""],
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={
              step === 1
                ? Yup.object({ email: forgotPasswordValidationSchema.email })
                : step === 2
                  ? Yup.object({ otp: forgotPasswordValidationSchema.otp })
                  : Yup.object({
                      newPassword: forgotPasswordValidationSchema.newPassword,
                      confirmPassword:
                        forgotPasswordValidationSchema.confirmPassword,
                    })
            }
            onSubmit={
              step === 1
                ? async (values, { setSubmitting, setErrors }) => {
                    setSubmitting(true);
                    try {
                      await authService.sendOtp(values.email);
                      setSuccessMessage("OTP sent to your email.");
                      setStep(2);
                    } catch (err) {
                      const message =
                        err.response?.data?.error ||
                        "Failed to send OTP. Please try again.";
                      setErrors({ email: message });
                      setForgotPasswordError(message);
                    } finally {
                      setSubmitting(false);
                    }
                  }
                : step === 2
                  ? async (values, { setSubmitting, setErrors }) => {
                      setSubmitting(true);
                      try {
                        await authService.verifyOtp(
                          values.email,
                          values.otp.join("")
                        );
                        setSuccessMessage(
                          "OTP verified! You can now reset your password."
                        );
                        setStep(3);
                      } catch (err) {
                        const message =
                          err.response?.data?.error ||
                          "Failed to verify OTP. Please try again.";
                        setErrors({ otp: message });
                      } finally {
                        setSubmitting(false);
                      }
                    }
                  : async (values, { setSubmitting, setErrors }) => {
                      setSubmitting(true);
                      try {
                        await authService.resetPassword(
                          values.email,
                          values.newPassword
                        );
                        setSuccessMessage("Password reset successfully!");
                        setStep(4);

                        setTimeout(() => {
                          handleForgotPasswordClose();
                        }, 2000);
                      } catch (err) {
                        const message =
                          err.response?.data?.error ||
                          "Failed to reset password. Please try again.";
                        setErrors({ newPassword: message });
                      } finally {
                        setSubmitting(false);
                      }
                    }
            }
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                {step === 1 && (
                  <>
                    <FormikInput
                      name="email"
                      label="Email Address"
                      type="email"
                      placeholder="Enter your email address"
                      fullWidth
                      margin="normal"
                    />
                  </>
                )}
                {step === 2 && <OtpInput name="otp" />}
                {errors.otp && touched.otp && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    {errors.otp}
                  </Typography>
                )}
                {step === 3 && (
                  <>
                    <FormikInput
                      name="newPassword"
                      label="New Password"
                      type="password"
                      placeholder="Enter new password"
                      fullWidth
                      margin="normal"
                    />
                    <FormikInput
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      placeholder="Confirm new password"
                      fullWidth
                      margin="normal"
                    />
                  </>
                )}
                {step === 4 && (
                  <Box sx={{ textAlign: "center", mt: 2 }}>
                    <Typography variant="h6" color="success.main">
                      Password reset successfully!
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      You can now use your new password to log in.
                    </Typography>
                    <MuiLoadingButton
                      onClick={handleForgotPasswordClose}
                      sx={{ textTransform: "none", mt: 2 }}
                    >
                      Close
                    </MuiLoadingButton>
                  </Box>
                )}
                {step !== 4 && (
                  <MuiLoadingButton
                    type="submit"
                    loading={isSubmitting}
                    sx={{ textTransform: "none", mt: 2 }}
                  >
                    {step === 1
                      ? "Send OTP"
                      : step === 2
                        ? "Verify OTP"
                        : "Reset Password"}
                  </MuiLoadingButton>
                )}
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Login;
