import React, { useState } from "react";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import axiosServices from "../utils/axiosServices";
import Loader from "../components/loader";
import DragAndDrop from "../components/DragAndDrop/DragDropBox";

function AddProject({ onSuccess, onFailure }) {
  const [formData, setFormData] = useState({
    projectName: "",
    description: "",
    file: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    projectName: false,
    description: false,
    file: false,
  });

  const [uploadedFileName, setUploadedFileName] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "projectName" || name === "description") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value === "",
      }));
    }
  };

  const handleFileSelected = (file) => {
    setFormData({ ...formData, file });
    setUploadedFileName(file.name);
    setErrors((prevErrors) => ({ ...prevErrors, file: false }));
  };

  const handleFileDelete = () => {
    setFormData((prevData) => ({ ...prevData, file: null }));
    setUploadedFileName("");
    setErrors((prevErrors) => ({ ...prevErrors, file: false }));
    document.getElementById("fileInput").value = "";
  };

  const createProject = async () => {
    setIsLoading(true);
    let input = new FormData();
    input.append("file", formData.file);
    input.append("projectName", formData.projectName);
    input.append("description", formData.description);
    console.log("input", input);
    try {
      const response = await axiosServices.post("/projects", input, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("API Response:", response);

      if (response.status >= 200 && response.status < 300) {
        if (response.data?.message === "Project created successfully") {
          console.log("Project creation successful");
          setErrors({
            projectName: false,
            description: false,
            file: false,
          });
          onSuccess();
        } else {
          console.error("Unexpected API response:", response.data);
          onFailure();
        }
      } else {
        console.error("API returned unexpected status:", response.status);
        onFailure();
      }
    } catch (error) {
      console.error("Error in createProject:", error);
      onFailure();
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      projectName: formData.projectName === "",
      description: formData.description === "",
      file: formData.file === null,
    };

    setErrors(newErrors);

    if (!Object.values(newErrors).some(Boolean)) {
      createProject();
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <Box mb={3}>
          <Typography
            variant="h6"
            component="h1"
            align="left"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#525252" }}
          >
            Upload Codebase
          </Typography>
          <Typography
            variant="subtitle1"
            align="left"
            sx={{ ml: 0.3, color: "#525252" }}
          >
            Fill the following information to get started
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            sx={{
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
            label="Project Name"
            name="projectName"
            fullWidth
            margin="normal"
            value={formData.projectName}
            onChange={handleChange}
            error={errors.projectName}
            helperText={errors.projectName && "Project Name is required"}
            required
            onBlur={() =>
              setErrors((prevErrors) => ({
                ...prevErrors,
                projectName: formData.projectName === "",
              }))
            }
          />
          <TextField
            sx={{
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
            label="Project Description"
            name="description"
            fullWidth
            margin="normal"
            multiline
            rows={3}
            value={formData.description}
            onChange={handleChange}
            error={errors.description}
            helperText={errors.description && "Description is required"}
            required
            onBlur={() =>
              setErrors((prevErrors) => ({
                ...prevErrors,
                description: formData.description === "",
              }))
            }
          />
          <Box mt={2}>
            <DragAndDrop
              onFileSelected={handleFileSelected}
              required
              id="fileInput"
            />
            {uploadedFileName && (
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ display: "block", textAlign: "center", mt: 1 }}
              >
                Uploaded File: {uploadedFileName}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleFileDelete}
                  sx={{ ml: 1 }}
                >
                  Delete
                </Button>
              </Typography>
            )}
            {errors.file && (
              <Typography
                variant="caption"
                color="error"
                sx={{ display: "block", textAlign: "center", mt: 1 }}
              >
                File is required
              </Typography>
            )}
          </Box>

          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              align="center"
              sx={{ px: 5, textTransform: "none" }}
            >
              Create Project
            </Button>
          </Box>
        </form>
      </Container>
      <Loader open={isLoading} />
    </>
  );
}

export default AddProject;
