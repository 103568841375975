import React, { useEffect, useState, useCallback } from "react";
import { Box, Skeleton } from "@mui/material";
import axiosServices from "../../utils/axiosServices";
import { useAuth } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import ReusableAccordion from "../../components/ReusableAccordion/ReusableAccordion";

const ExecutiveSummary = () => {
  const location = useLocation();
  const { user } = useAuth();
  const project = location.state?.project;
  const projectId = project?.project_id;
  const userEmail = user?.user?.email;
  const [executiveSummary, setExecutiveSummary] = useState({});
  const [loading, setLoading] = useState(true);

  const getSummary = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosServices.get(`/summary`, {
        params: {
          project_id: projectId,
          email: userEmail,
        },
      });
      if (!response.data) {
        setExecutiveSummary({});
        return;
      }
      const processedData = JSON.parse(response.data);

      if (processedData) {
        setExecutiveSummary(processedData);
        console.log("Fetched Executive Summary Data:", processedData);
      } else {
        setExecutiveSummary({});
      }
    } catch (error) {
      console.error("Error fetching executive summary:", error);
      setExecutiveSummary({});
    } finally {
      setLoading(false);
      console.log("Fetch operation completed.");
    }
  }, [projectId, userEmail]);

  useEffect(() => {
    getSummary();
  }, [getSummary]);

  const formatDescription = (description) => {
    const cleanedDescription = description.replace(/```markdown\n|\n```/g, "");

    return cleanedDescription
      .replace(
        /# (.*?)(\n|$)/g,
        "<h2 style='font-size: 1.25rem; font-weight: bold;'>$1</h2>"
      )
      .replace(
        /## (.*?)(\n|$)/g,
        "<h3 style='font-size: 1rem; font-weight: bold;'>$1</h3>"
      )
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(/\n/g, "<br/>")
      .replace(/(\d+)\. /g, "<li>$1. ");
  };

  return (
    <div>
      <Box sx={{ position: "relative", ml: 2 }}>
        <Box
          sx={{
            position: "absolute",
            left: 10,
            top: "1px",
            height: `calc(100% - 12px)`,
            width: "1px",
            backgroundColor: "#58A94233",
          }}
        />

        {loading ? (
          <Box>
            {[...Array(5)].map((_, index) => (
              <Box key={index} sx={{ marginBottom: "16px" }}>
                <ReusableAccordion>
                  <Skeleton
                    variant="rectangular"
                    height={50}
                    sx={{ marginTop: 1 }}
                  />
                </ReusableAccordion>
              </Box>
            ))}
          </Box>
        ) : (
          Object.entries(executiveSummary).map(
            ([title, description], index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  position: "relative",
                  mb: 0.5,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    marginBottom: "10px",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      left: "7px",
                      top: "15px",
                      width: "5.5px",
                      height: "5.5px",
                      backgroundColor: "#58A942",
                      borderRadius: "50%",
                      zIndex: 1,
                    },
                  }}
                />

                <Box sx={{ ml: 4, flex: 1, wordWrap: "break-word" }}>
                  <ReusableAccordion title={title}>
                    <Box
                      component="ul"
                      sx={{ p: 0, listStyleType: "none", fontSize: "0.875rem" }}
                      dangerouslySetInnerHTML={{
                        __html: formatDescription(description),
                      }}
                    />
                  </ReusableAccordion>
                </Box>
              </Box>
            )
          )
        )}
      </Box>
    </div>
  );
};

export default ExecutiveSummary;
