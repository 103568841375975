import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ReusableAccordion from "../../components/ReusableAccordion/ReusableAccordion";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const PinnedTopics = ({
  groupedTopics,
  isLoading,
  error,
  noDataPinnedMessage,
}) => {
  const [expandedResponses, setExpandedResponses] = useState({});

  const toggleResponseExpansion = (id) => {
    setExpandedResponses((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const formatSnippet = (response) => {
    return response.split(" ").slice(0, 5).join(" ") + "...";
  };

  const formatAssistantResponse = (response) => {
    if (!response) return null;

    return response.split("\n").map((line, idx) => {
      const cleanedLine = line.trim().replace(/\*\*/g, "");
      const headerRemovedLine = cleanedLine.replace(/^#+\s*/, "");
      if (/^\d+\./.test(headerRemovedLine)) {
        const [titleWithNumber, ...rest] = headerRemovedLine.split(":");
        const restText = rest.join(":").trim();

        return (
          <Box
            key={`line-${idx}`}
            sx={{ display: "flex", alignItems: "baseline", marginBottom: 1 }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginRight: 1,
                fontSize: "0.9rem",
                color: "#333",
              }}
            >
              {titleWithNumber}:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "0.9rem",
                color: "#555",
              }}
            >
              {restText}
            </Typography>
          </Box>
        );
      }

      return (
        <Typography
          key={`line-${idx}`}
          variant="body2"
          sx={{
            fontSize: "1rem",
            color: "#333",
            marginBottom: 1,
            lineHeight: 1.6,
          }}
        >
          {headerRemovedLine}
        </Typography>
      );
    });
  };

  return (
    <Box>
      {isLoading ? (
        <Box>
          {/* {[...Array(3)].map((_, index) => (
            <Box key={index} sx={{ marginBottom: "16px" }}>
              <Skeleton variant="text" width="60%" height={30} />
              <Skeleton
                variant="rectangular"
                height={50}
                sx={{ marginTop: 1 }}
              />
            </Box>
          ))} */}
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : noDataPinnedMessage ? (
        <Typography color="textSecondary">{noDataPinnedMessage}</Typography>
      ) : (
        Object.entries(groupedTopics).map(([topicName, responses]) => (
          <Box key={topicName}>
            <ReusableAccordion key={topicName} title={topicName}>
              {responses.map(({ id, content }) => (
                <Box key={id}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.9rem",
                        flex: 1,
                        margin: "10px 5px",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      {expandedResponses[id]
                        ? formatAssistantResponse(content)
                        : formatSnippet(content)}
                    </Typography>

                    <Box>
                      <IconButton
                        size="small"
                        onClick={() => toggleResponseExpansion(id)}
                      >
                        {expandedResponses[id] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}
            </ReusableAccordion>
          </Box>
        ))
      )}
    </Box>
  );
};

export default PinnedTopics;
