import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Avatar,
  Dialog,
  DialogContent,
  List,
  Snackbar,
  Button,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import axiosServices from "../../utils/axiosServices";
import logo from "../../assets/images/logo.svg";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { ThreeDots } from "react-loader-spinner";
import { Send } from "@mui/icons-material";
import Markdown from "react-markdown";
import CopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";

const Chat = ({
  project,
  messages,
  setMessages,
  pinnedTopics,
  handlePinMessage,
  handleSendMessage,
  handleDelete,
}) => {
  const { user } = useAuth();
  const [groupedTopics, setGroupedTopics] = useState({});
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [newTopic, setNewTopic] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const chatContainerRef = useRef(null);
  const [selectedMessageContent, setSelectedMessageContent] = useState("");
  const [pinnedMessages, setPinnedMessages] = useState(new Set());
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isAddingToTopic, setIsAddingToTopic] = useState(false);

  useEffect(() => {
    const newPinnedMessages = new Set(
      pinnedTopics.map((topic) => topic.pin_content)
    );
    setPinnedMessages(newPinnedMessages);
  }, [pinnedTopics]);

  useEffect(() => {
    const topics = pinnedTopics.reduce((acc, topic) => {
      acc[topic.topic_name] = acc[topic.topic_name] || [];
      acc[topic.topic_name].push(topic);
      return acc;
    }, {});
    setGroupedTopics(topics);
  }, [pinnedTopics]);

  const getChatHistory = useCallback(async () => {
    if (!user?.user?.email || !project?.project_id) return;
    setIsLoading(true);
    try {
      const response = await axiosServices.get(
        `/messages?email=${user.user.email}&projectId=${project.project_id}`
      );
      setMessages(response?.data || []);
    } catch (error) {
      console.error("Error fetching chat history:", error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.user?.email, project?.project_id, setMessages]);

  useEffect(() => {
    if (project) {
      getChatHistory();
    }
  }, [project, getChatHistory]);

  useEffect(() => {
    chatContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const onSendMessage = async () => {
    setIsMessageLoading(true);
    await handleSendMessage(message);
    setMessage("");
    setIsMessageLoading(false);
  };

  const handleOpenDialog = (msgContent) => {
    setSelectedMessageContent(msgContent);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedMessageContent("");
    setNewTopic("");
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSelectTopic = async (topicName) => {
    // Log the type and value of topicName for debugging
    console.log("Received topicName:", topicName, "Type:", typeof topicName);

    // Ensure topicName is a string before trimming
    if (typeof topicName !== "string") {
      console.error("Invalid topicName:", topicName);
      // Convert to string if it's not already
      topicName = String(topicName);
    }

    // Check if topicName is still not a string after conversion
    if (typeof topicName !== "string") {
      console.error("topicName is still not a string:", topicName);
      return; // Exit if it's still not a string
    }

    // Prevent calling trim on an undefined or null value
    if (topicName) {
      setIsAddingToTopic(true);
      const success = await handlePinMessage(
        topicName.trim(),
        selectedMessageContent
      );
      if (success) {
        setPinnedMessages((prev) => new Set(prev).add(selectedMessageContent));
        setSnackbarMessage(`Content has been pinned!`);
        setSnackbarOpen(true);
        setOpenDialog(false);
        console.log("Dialog closed after selecting topic.");
      } else {
        console.error("Failed to pin the selected topic.");
      }
      setIsAddingToTopic(false);
    } else {
      console.error("topicName is empty or invalid.");
    }
  };

  const handleCopyMessage = (msgContent) => {
    navigator.clipboard
      .writeText(msgContent)
      .then(() => {
        console.log("Message copied to clipboard:", msgContent);
        setSnackbarOpen(true);
        setSnackbarMessage("Message copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  const handleSaveNewTopic = async () => {
    if (newTopic.trim()) {
      setIsMessageLoading(true);
      const success = await handlePinMessage(newTopic, selectedMessageContent);
      if (success) {
        setPinnedMessages((prev) => new Set(prev).add(selectedMessageContent));
        setSnackbarMessage(`New topic "${newTopic}" has been created!`);
        setSnackbarOpen(true);
        setOpenDialog(false);
        console.log("Dialog closed after successful pinning.");
      } else {
        console.error("Failed to pin the message.");
      }
      setNewTopic("");
      setIsMessageLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          flex: 1,
          overflowY: isMessageLoading ? "hidden" : "auto",
          mb: 2,
        }}
      >
        {messages.length > 0
          ? isLoading
            ? ""
            : messages.map((msg, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        msg.role === "user" ? "flex-end" : "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent:
                          msg.role === "user" ? "flex-end" : "flex-start",
                        pl: "44px",
                      }}
                    >
                      {msg.role === "assistant" && (
                        <Box
                          sx={{
                            display: "flex",
                            pt: "22px",
                          }}
                        >
                          <Avatar
                            src={logo}
                            alt="Assistant Avatar"
                            sx={{
                              width: 35,
                              height: 35,
                              border: "2px solid #D9D9D9",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                      )}
                      <Box
                        sx={{
                          backgroundColor: msg.role === "user" ? "#f0f0f0" : "",
                          color: "#000",
                          borderRadius: "20px",
                          padding: "10px 10px",
                          mt: "5px",
                          maxWidth: "fit-content",
                          alignSelf:
                            msg.role === "user" ? "flex-end" : "flex-start",
                          boxShadow:
                            msg.role === "user"
                              ? "0px 2px 4px rgba(0, 0, 0, 0.1)"
                              : "",
                          fontSize: msg.role === "user" ? "1rem" : "1rem",
                        }}
                      >
                        {msg.role === "assistant" ? (
                          <Markdown
                            components={{
                              h1: ({ children }) => (
                                <h1
                                  style={{
                                    fontSize: "1rem", // Reduced size for h1
                                    fontWeight: "bold",
                                  }}
                                >
                                  {children}
                                </h1>
                              ),
                              h2: ({ children }) => (
                                <h2
                                  style={{
                                    fontSize: "1rem", // Reduced size for h2
                                    fontWeight: "600",
                                  }}
                                >
                                  {children}
                                </h2>
                              ),
                              h3: ({ children }) => (
                                <h3
                                  style={{
                                    fontSize: "1rem", // Reduced size for h3
                                    fontWeight: "500",
                                  }}
                                >
                                  {children}
                                </h3>
                              ),
                              p: ({ children }) => (
                                <p
                                  style={{
                                    fontSize: "1rem", // Reduced size for paragraphs
                                    margin: "0.5rem 0", // Add some spacing
                                  }}
                                >
                                  {children}
                                </p>
                              ),
                              ul: ({ children }) => (
                                <ul
                                  style={{
                                    fontSize: "1rem", // Reduced size for list items
                                    paddingLeft: "1rem",
                                  }}
                                >
                                  {children}
                                </ul>
                              ),
                              li: ({ children }) => (
                                <li
                                  style={{
                                    marginBottom: "1rem", // Add spacing between list items
                                  }}
                                >
                                  {children}
                                </li>
                              ),
                            }}
                          >
                            {msg.content}
                          </Markdown>
                        ) : (
                          msg.content
                        )}
                      </Box>
                    </Box>

                    {msg.role === "assistant" && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginTop: "-5px",
                          marginLeft: "80px",
                        }}
                      >
                        <IconButton
                          sx={{
                            color: pinnedMessages.has(msg.content)
                              ? "green"
                              : "#525252",
                          }}
                          onClick={async () => {
                            console.log(
                              "Pin clicked for message:",
                              msg.content
                            );
                            console.log("Current pinnedTopics:", pinnedTopics);
                            if (pinnedMessages.has(msg.content)) {
                              const pin = pinnedTopics.find((p) => {
                                console.log(
                                  "Comparing with pin_content:",
                                  p.pin_content
                                );
                                console.log("msg.content:", msg.content);
                                return (
                                  p.pin_content.trim() === msg.content.trim()
                                );
                              });

                              console.log("Found pin:", pin);
                              if (pin) {
                                console.log("Deleting pin with ID:", pin.id);
                                await handleDelete(pin.id);
                                setSnackbarMessage(
                                  `The message has been unpinned successfully!`
                                );
                                setSnackbarOpen(true);
                                setPinnedMessages((prev) => {
                                  const updated = new Set(prev);
                                  updated.delete(msg.content);
                                  return updated;
                                });
                              } else {
                                console.error(
                                  "Pin not found for message:",
                                  msg.content
                                );
                              }
                            } else {
                              setSelectedMessageContent(msg.content);
                              handleOpenDialog(msg.content);
                            }
                          }}
                        >
                          <PushPinIcon
                            fontSize="small"
                            sx={{
                              transform: "rotate(45deg)",
                              color: pinnedMessages.has(msg.content)
                                ? "green"
                                : "inherit",
                            }}
                            style={{
                              display: pinnedMessages.has(msg.content)
                                ? "block"
                                : "none",
                            }}
                          />
                          {!pinnedMessages.has(msg.content) && (
                            <PushPinOutlinedIcon
                              fontSize="small"
                              sx={{
                                transform: "rotate(45deg)",
                                color: "#525252",
                              }}
                            />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() => handleCopyMessage(msg.content)}
                          sx={{ color: "#525252" }}
                        >
                          <CopyIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                );
              })
          : ""}

        {isMessageLoading && (
          <Box sx={{ display: "flex", pl: "80px", height: "-10%" }}>
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#525252"
              ariaLabel="three-dots-loading"
              visible={true}
            />
          </Box>
        )}
        <div ref={chatContainerRef} />
      </Box>

      <Box
        sx={{
          position: "fixed",
          width: "65%",
          bottom: 0,
          zIndex: 1,
          p: 1,
          pl: 5.5,
          pb: 8,
          background: "white",
          "@media (max-width: 1024px)": {
            width: "100%",
            pl: 3,
            pr: 3,
          },
          "@media (max-width: 768px)": {
            width: "100%",
            pl: 2,
            pr: 2,
          },
        }}
      >
        <TextField
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onSendMessage();
            }
          }}
          placeholder="Ask Ai a question or make a request..."
          disabled={isMessageLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={onSendMessage}
                  disabled={isMessageLoading}
                  color="primary"
                >
                  <Send sx={{ transform: "rotate(-45deg)", mr: "10px" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              background: "white",
              borderRadius: "10px",
              boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #D9D9D9",
                pb: "10px",
              },
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #D9D9D9",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #D9D9D9",
              },
            "@media (max-width: 1024px)": {
              width: "100%",
            },
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        />
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{
          "& .MuiDialog-paper": {
            // height: "40vh",
            // width: "450px",
            height: Object.keys(groupedTopics).length === 0 ? "20vh" : "40vh",
            width: Object.keys(groupedTopics).length === 0 ? "450px" : "450px",
            maxWidth: "50vw",
            border: "2px solid #CDCDCD",
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent
          sx={{
            marginBottom: "20px",
            overflowY: "hidden",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", textAlign: "left" }}
            >
              Topics
            </Typography>

            <Box
              sx={{
                flex: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <List>
                {Object.entries(groupedTopics).map(([topicName]) => (
                  <Box
                    key={topicName}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      textAlign: "left",
                      paddingBottom: "10px",
                      width: "200%",
                    }}
                    onClick={() => {
                      console.log("Clicking topic:", topicName);
                      handleSelectTopic(topicName);
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        flex: 1,
                        textAlign: "left",
                        border: "1px solid #F8F8F8",
                        backgroundColor: "#F8F8F8",
                        padding: "5px",
                        borderRadius: "5px",
                        mr: 5,
                        width: "100%",
                        "&:hover": {
                          border: "1px solid #F8F8F8",
                          backgroundColor: "#F8F8F8",
                          padding: "5px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontWeight: "bold",
                          fontSize: "15px",
                          color: "green",
                          mr: 1,
                        },
                      }}
                    >
                      {topicName}
                    </Typography>
                  </Box>
                ))}
              </List>
            </Box>
            {isAddingToTopic && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  zIndex: 1,
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            )}
            {Object.keys(groupedTopics).length === 0 && (
              <Typography
                variant="body2"
                sx={{
                  padding: "10px 0 8px 0",
                  color: "#737373",
                  fontSize: "12px",
                  fontWeight: "bold",
                  ml: 0.5,
                }}
              >
                Create a new topic here!
              </Typography>
            )}
            {Object.keys(groupedTopics).length > 0 && (
              <Typography
                variant="body2"
                sx={{
                  padding: "10px 0 8px 0",
                  color: "#737373",
                  fontSize: "12px",
                  fontWeight: "bold",
                  ml: 0.5,
                }}
              >
                Or Create a New Topic Here!
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1.5,
                ml: 0.5,
              }}
            >
              <TextField
                sx={{
                  width: "300px",
                  "& .MuiOutlinedInput-root": {},
                  "& fieldset": {
                    border: "none",
                  },
                  border: "1px solid green",
                  borderRadius: "5px",
                }}
                size="small"
                value={newTopic}
                onChange={(e) => setNewTopic(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSaveNewTopic();
                  }
                }}
              />

              <Button
                onClick={handleSaveNewTopic}
                sx={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "13px",
                  backgroundColor: "green",
                  border: "1px solid green",
                  borderRadius: "5px",
                  width: "100px",
                }}
                disabled={isMessageLoading}
              >
                {isMessageLoading ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress
                      size={5} // Adjust size as needed
                      color="inherit"
                      sx={{ mr: 1 }} // Margin to separate from text
                    />
                    Loading... {/* Text to indicate loading */}
                  </Box>
                ) : (
                  <>
                    Create
                    <AddIcon sx={{ ml: 1 }} />
                  </>
                )}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Chat;
